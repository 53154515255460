import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import RegionsTabularPerformanceTable from "./RegionsTabularPerformanceTable";
import ChannelsTabularPerformanceTable from "./ChannelsTabularPerformanceTable";
import AgentsTabularPerformanceTable from "./AgentsTabularPerformanceTable";
import ProductCategoriesTabularPerformanceTable from "./ProductCategoriesTabularPerformanceTable";
import ProductSubCategoriesTabularPerformanceTable from "./ProductSubCategoriesTabularPerformanceTable";

const TabularReportsPage = () => {
    const [selectedTable, setSelectedTable] = useState("Channels");

    // Dropdown options to switch tables
    const tableOptions = [
        { label: "Channels Tabular Performance", value: "Channels" },
        { label: "Regions Tabular Performance", value: "Regions" },
        { label: "Business Partners Tabular Performance", value: "Agents" },
        // { label: "Routes Tabular Performance", value: "Routes" },
        // { label: "Agents Tabular Performance", value: "Agents" },
        { label: "Product Categories Tabular Performance", value: "product-categories" },
        { label: "Product Sub Categories Tabular Performance", value: "product-sub-categories" },
    ];

    // Handling table selection
    const handleTableChange = (e) => {
        setSelectedTable(e.value);
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                <Dropdown value={selectedTable} options={tableOptions} onChange={handleTableChange} placeholder="Select a Table" />
            </div>

            {selectedTable === "Channels" && <ChannelsTabularPerformanceTable />}
            {selectedTable === "Regions" && <RegionsTabularPerformanceTable />}
            {/* {selectedTable === "Routes" && <RoutesTabularPerformanceTable />} */}
            {selectedTable === "Agents" && <AgentsTabularPerformanceTable />}
            {selectedTable === "product-categories" && <ProductCategoriesTabularPerformanceTable />}
            {selectedTable === "product-sub-categories" && <ProductSubCategoriesTabularPerformanceTable />}
        </div>
    );
};

export default TabularReportsPage;
