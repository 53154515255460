import axiosAPI from "../axiosApi";

//=================== perfomance ======================

export async function getSelloutRegionsPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSelloutRegionsPerfomanceTable", { params: params });
    return response;
}

export async function getSelloutAgentsPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSelloutAgentsPerfomanceTable", { params: params });
    return response;
}

export async function getSelloutRoutesPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSelloutRoutesPerfomanceTable", { params: params });
    return response;
}

export async function getSelloutProductCategoriesPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSelloutProductCategoriesPerfomanceTable", { params: params });
    return response;
}

export async function getSelloutProductSubCategoriesPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSelloutProductSubCategoriesPerfomanceTable", { params: params });
    return response;
}
