import React, { lazy, Suspense, useState, useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";

//==================== my car routes ====================
import NewUsersPage from "./views/users/UserPage";

import AgentsPage from "./views/agents/AgentsPage";
import ChannelsPage from "./views/channels/ChannelsPage";
import ProductCategoriesPage from "./views/product-categories/ProductCategoriesPage";
import ProductCategoryViewPage from "./views/product-categories/ProductCategoryViewPage";
import ProductSubCategoriesPage from "./views/product-sub-categories/ProductSubCategoriesPage";
import ProductSubCategoryViewPage from "./views/product-sub-categories/ProductSubCategoryViewPage";
import ProductsPage from "./views/products/ProductsPage";

import RegionsPage from "./views/regions/RegionsPage";
import RoutesPage from "./views/routes/RoutesPage";
import SalesAssociatesPage from "./views/sales-associates/SalesAssociatesPage";
import CustomersPage from "./views/customers/CustomersPage";
import TransactionsPage from "./views/transactions/TransactionsPage";

import NewFileImportsPage from "./views/settings/file-imports/FileImportsPage";
import NewDashbaordPage from "./views/dashboard/DashbaordPage";
import Excelmport from "./views/settings/excel-imports/ExcelmportPage";

import NiceTwoDashbaordPage from "./views/dashboard-nice-two/NiceTwoDashbaordPage";

import SellInHeatMapPage from "./views/sell-in-heat-map/SellInHeatMapPage";
import SellOutHeatMapPage from "./views/sell-out-heat-map/SellOutHeatMapPage";

import SellOutTabularReportsPage from "./views/sell-out-tarbular-reports/TabularReportsPage";
import SellInTabularReportsPage from "./views/sell-in-tarbular-reports/TabularReportsPage";

//
import useAuthContext from "./context/AuthContext";

//
import NotFoundPage from "./components/not_found/NotFoundPage";

function AppRoutes() {
    //
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;
    // ...(activeUser?.role.includes("Admin") || activeUser?.role.includes("Dashboard Viewer")
    const commonRoutes = [
        {
            path: "/sell-in",
            name: "sell-in",
            element: <NewDashbaordPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-out",
            name: "sell-out",
            element: <NiceTwoDashbaordPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-in-heat-map",
            name: "sell-in-heat-map",
            element: <SellInHeatMapPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-out-heat-map",
            name: "sell-out-heat-map",
            element: <SellOutHeatMapPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-out-tabular-reports",
            name: "sell-out-tabular-reports",
            element: <SellOutTabularReportsPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-in-tabular-reports",
            name: "sell-in-tabular-reports",
            element: <SellInTabularReportsPage />, // Replace with the actual component
            layout: "/private",
        },
    ];

    // ============================= Nice Admin Routes ==================================
    // ...(activeUser?.role.includes("Admin")
    const adminRoutes = [
        {
            path: "/users",
            name: "users",
            element: <NewUsersPage loggedInUserData={getUserQuery?.data?.data} />,
            layout: "/admin",
        },

        {
            path: "/agents",
            name: "agents",
            element: <AgentsPage />, // Agents component
            layout: "/private",
        },
        {
            path: "/customers",
            name: "customers",
            element: <CustomersPage />, // Agents component
            layout: "/private",
        },

        {
            path: "/channels",
            name: "channels",
            element: <ChannelsPage />, // Channels component
            layout: "/private",
        },
        {
            path: "/product-categories",
            name: "product-categories",
            element: <ProductCategoriesPage />, // Product Categories component
            layout: "/private",
        },
        {
            path: "/product-categories/product-category",
            name: "/product-categories/product-category",
            element: <ProductCategoryViewPage />, // Product Categories component
            layout: "/private",
        },
        {
            path: "/product-subcategories",
            name: "product-subcategories",
            element: <ProductSubCategoriesPage />, // Product Subcategories component
            layout: "/private",
        },
        {
            path: "/product-subcategories/product-sub-category",
            name: "/product-subcategories/product-sub-category",
            element: <ProductSubCategoryViewPage />, // Product Subcategories component
            layout: "/private",
        },
        {
            path: "/product-categories/product-category/product-sub-category",
            name: "/product-categories/product-category/product-sub-category",
            element: <ProductSubCategoryViewPage />, // Product Subcategories component
            layout: "/private",
        },
        {
            path: "/products",
            name: "products",
            element: <ProductsPage />, // Products component
            layout: "/private",
        },
        {
            path: "/regions",
            name: "regions",
            element: <RegionsPage />, // Regions component
            layout: "/private",
        },
        {
            path: "/routes",
            name: "routes",
            element: <RoutesPage />, // Routes component
            layout: "/private",
        },
        {
            path: "/sales-associates",
            name: "sales-associates",
            element: <SalesAssociatesPage />, // Sales Associates component
            layout: "/private",
        },
        {
            path: "/transactions",
            name: "transactions",
            element: <TransactionsPage />, // Sales Associates component
            layout: "/private",
        },

        {
            path: "/import",
            name: "import",
            element: <NewFileImportsPage />,
            layout: "/admin",
        },
        {
            path: "/excel",
            element: <Excelmport />,
        },
        {
            path: "/sell-out-tabular-reports",
            name: "sell-out-tabular-reports",
            element: <SellOutTabularReportsPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/sell-in-tabular-reports",
            name: "sell-in-tabular-reports",
            element: <SellInTabularReportsPage />, // Replace with the actual component
            layout: "/private",
        },
    ];

    const routes = useRoutes([
        ...(activeUser?.role.includes("Admin") || activeUser?.role.includes("Dashboard Viewer") ? commonRoutes : []),
        ...(activeUser?.role.includes("Admin") ? adminRoutes : []),
        { path: "*", element: <NotFoundPage /> }, // Handle 404
    ]);

    return routes;
}

export default AppRoutes;
