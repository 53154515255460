import React, { useContext, useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Dialog } from "primereact/dialog";
import numeral from "numeral";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import SellinStatsGaugeTarget from "./SellinStatsGaugeTarget";
import { Grid, Stack } from "@mui/material";
import { getSellinRevenueGauge, getSellinRunRate, getSellinSpotLanding, getSellinDailyCharts, getSellinWeeklyCharts, getSellinMonthlyCharts, getSellinYearlyCharts } from "../../../services/dashboard/cards";

import { DashboardFilterContext } from "../../../context/DashboardFilterContext";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

//
import { Tooltip, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";
import CardsFiltersFormDialog from "./widgets/CardsFiltersFormDialog";

import { Button } from "primereact/button";
//lotties
import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
import noData from "highcharts/modules/no-data-to-display";

import FiltersFormDialog from "../widgets/FiltersFormDialog";

const formatNumber = (number) => {
    return numeral(number).format("0.[00]a").toUpperCase(); // e.g., 3.5k, 3.45m, 3.4b
};

const getChangeColor = (change) => {
    if (change > 100) {
        return "green";
    } else if (change < 90) {
        return "red";
    } else {
        return "orange";
    }
};

const getChangeColor2 = (change) => {
    if (change > 100) {
        return "red";
    } else if (change < 90) {
        return "green";
    } else {
        return "orange";
    }
};

Highcharts.setOptions({
    lang: {
        thousandsSep: ",",
    },
});

const SalesCard = ({ title, categories, tooltip, chartData, color, measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const sply = chartData[0]["data"][2];
    const actual = chartData[1]["data"][2];
    const target = chartData[2]["data"][2];

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },
        title: {
            text: `${title} Vs SPLY Vs Target`,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: chartData,
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <Tooltip title={tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    <i className="pi pi-chart-bar text-blue-500 text-xl" />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% (Δ% SPLY)</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                        </span>
                    </div>
                )}
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% (Δ% Target)</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                        </span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RevenueTodayCard = ({ measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const DailyRevenueToDateQuery = useQuery({
        disable: false,
        queryKey: ["sellin-daily-statistics", measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinDailyCharts({ measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (DailyRevenueToDateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ DailyRevenueToDateQuery?.isError:", DailyRevenueToDateQuery?.error);
            DailyRevenueToDateQuery?.error?.response?.data?.message ? toast.error(DailyRevenueToDateQuery?.error?.response?.data?.message) : !DailyRevenueToDateQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [DailyRevenueToDateQuery?.isError]);

    if (DailyRevenueToDateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (DailyRevenueToDateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo DailyRevenueToDateQuery?.data?.data is:", DailyRevenueToDateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, DailyRevenueToDateQuery?.data?.data);

    const { title, categories, tooltip, chartData } = DailyRevenueToDateQuery?.data?.data?.data[0];

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const sply = chartData[0]["data"][2];
    const actual = chartData[1]["data"][2];
    const target = chartData[2]["data"][2];

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },
        title: {
            text: `${title} Vs SPLY Vs Target`,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: chartData,
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <Tooltip title={tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                        </span>
                    </div>
                )}
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% of the Target</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                        </span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RevenueWeekToDate = ({ measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const WeeklyRevenueToDateQuery = useQuery({
        disable: false,
        queryKey: ["sellin-weekly-statistics", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinWeeklyCharts({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (WeeklyRevenueToDateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ WeeklyRevenueToDateQuery?.isError:", WeeklyRevenueToDateQuery?.error);
            WeeklyRevenueToDateQuery?.error?.response?.data?.message ? toast.error(WeeklyRevenueToDateQuery?.error?.response?.data?.message) : !WeeklyRevenueToDateQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [WeeklyRevenueToDateQuery?.isError]);

    if (WeeklyRevenueToDateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (WeeklyRevenueToDateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo WeeklyRevenueToDateQuery?.data?.data is:", WeeklyRevenueToDateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, WeeklyRevenueToDateQuery?.data?.data);

    const { title, categories, tooltip, chartData } = WeeklyRevenueToDateQuery?.data?.data?.data[0];
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const sply = chartData[0]["data"][2];
    const actual = chartData[1]["data"][2];
    const target = chartData[2]["data"][2];

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },
        title: {
            text: `${title} Vs SPLY Vs Target`,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: chartData,
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <Tooltip title={tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                        </span>
                    </div>
                )}
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% of the Target</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                        </span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RevenueMonthToDate = ({ measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const MonthlyRevenueToDateQuery = useQuery({
        disable: false,
        queryKey: ["sellin-monthly-statistics", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinMonthlyCharts({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (MonthlyRevenueToDateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ MonthlyRevenueToDateQuery?.isError:", MonthlyRevenueToDateQuery?.error);
            MonthlyRevenueToDateQuery?.error?.response?.data?.message
                ? toast.error(MonthlyRevenueToDateQuery?.error?.response?.data?.message)
                : !MonthlyRevenueToDateQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [MonthlyRevenueToDateQuery?.isError]);

    if (MonthlyRevenueToDateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (MonthlyRevenueToDateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo MonthlyRevenueToDateQuery?.data?.data is:", MonthlyRevenueToDateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, MonthlyRevenueToDateQuery?.data?.data);

    const { title, categories, tooltip, chartData } = MonthlyRevenueToDateQuery?.data?.data?.data[0];
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const sply = chartData[0]["data"][2];
    const actual = chartData[1]["data"][2];
    const target = chartData[2]["data"][2];

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },
        title: {
            text: `${title} Vs SPLY Vs Target`,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: chartData,
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <Tooltip title={tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                        </span>
                    </div>
                )}
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% of the Target</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                        </span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RevenueYearToDate = ({ measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const YearlyRevenueToDateQuery = useQuery({
        disable: false,
        queryKey: ["sellin-yearly-statistics", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinYearlyCharts({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
        // staleTime: 0,
        // refetchOnWindowFocus: true,
    });

    useEffect(() => {
        if (YearlyRevenueToDateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ YearlyRevenueToDateQuery?.isError:", YearlyRevenueToDateQuery?.error);
            YearlyRevenueToDateQuery?.error?.response?.data?.message ? toast.error(YearlyRevenueToDateQuery?.error?.response?.data?.message) : !YearlyRevenueToDateQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [YearlyRevenueToDateQuery?.isError]);

    if (YearlyRevenueToDateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (YearlyRevenueToDateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo YearlyRevenueToDateQuery?.data?.data is:", YearlyRevenueToDateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, YearlyRevenueToDateQuery?.data?.data);

    const { title, categories, tooltip, chartData } = YearlyRevenueToDateQuery?.data?.data?.data[0];
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    console.log("chartData ziii", chartData);

    const sply = chartData[0]["data"][2];
    const actual = chartData[1]["data"][2];
    const target = chartData[2]["data"][2];

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },
        title: {
            text: `${title} Vs SPLY Vs Target`,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: chartData,
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <Tooltip title={tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                        </span>
                    </div>
                )}
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% of the Target</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                        </span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RunRateCard = ({ title, unit, categories, tooltip, actual, target, chartData, color, measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);

    const op = useRef(null);

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);
    // console.log("measure included", measure);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const RunRateQuery = useQuery({
        disable: false,
        queryKey: ["sell-in-bop-card", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinRunRate({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RunRateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RunRateQuery?.isError:", RunRateQuery?.error);
            RunRateQuery?.error?.response?.data?.message ? toast.error(RunRateQuery?.error?.response?.data?.message) : !RunRateQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RunRateQuery?.isError]);

    if (RunRateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (RunRateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo RunRateQuery?.data?.data is:", RunRateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, RunRateQuery?.data?.data);

    const data = RunRateQuery?.data?.data?.data;

    const actualVsTargetChange = calculatePercentageChange(data.actual, data.target);
    const actualVsSPLYChange = calculatePercentageChange(data.actual, data.sply);

    // Define the series options based on chartData
    // Define the series options based on chartData
    const options = {
        chart: {
            type: "column", // Using column chart for SPLY vs Actual
            height: "190",
            // backgroundColor: "#f4f4f4",
        },
        title: {
            text: data.title,
        },
        xAxis: {
            categories: data.categories,
        },
        yAxis: {
            // min: 0,
            title: {
                text: data.unit,
            },
        },
        series: [
            {
                name: "Run Rate",
                // colorByPoint: true,
                data: data.chartData,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "11px", // Set the font size of the data labels
                    },
                    format: `${measure === "quantity" ? "" : "UGX"} {point.y:,.0f}`,
                    // formatter: function () {
                    //     return `<b>${this.x}</b><br/>UGX ${this.y.toLocaleString()}`;
                    // },
                },
            },
        ],

        tooltip: {
            formatter: function () {
                return `<b>${this.x}</b><br/>${measure === "quantity" ? "" : "UGX"} ${this.y.toLocaleString()}`;
            },
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                },
                enableMouseTracking: true,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0" style={{ height: "300px" }}>
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{data.title}</span>
                    <Tooltip title={data.tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            BOP Run Rate: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center"
                    // style={{
                    //     width: "2.5rem",
                    //     height: "2.5rem",
                    // }}
                    // onClick={() => setVisible(true)}
                >
                    {/* <div style={{ position: "relative", top: "30px", marginTop: "-30px", zIndex: "20", width: "fit-content" }}> */}
                    <Tooltip title="How to interpret this chart">
                        <IconButton onClick={(e) => op.current.toggle(e)}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    {/* </div> */}
                    <OverlayPanel ref={op} style={{ maxWidth: "80vw", padding: "20px" }}>
                        <div>
                            <h4>Run Rate Card Guide</h4>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Overview</h5>
                            <p>The Run Rate Card provides a snapshot of progress towards a target, helping you gauge performance and stay on track.</p>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Key Components</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Daily Run Rate:</strong> Calculates the required daily rate to meet the target based on the total days in the period.
                                    <br />
                                    <strong>Formula:</strong> Daily Run Rate = Target / Total Number of Days in the period
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Balance of Period Run Rate (BOP Run Rate):</strong> Calculates the required rate to meet the target based on the remaining days in the period.
                                    <br />
                                    <strong>Formula:</strong> BOP Run Rate = Balance of Target / Number of Days Left to the end of the period
                                </li>
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Interpretation</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Percentage of Daily Run Rate</strong>
                                    <br />
                                    <strong>Formula:</strong> (BOP Run Rate / Daily Run Rate) * 100
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Percentage of Daily Run Rate SPLY</strong>
                                    <br />
                                    <strong>Formula:</strong> (BOP Run Rate / Daily Run Rate SPLY) * 100
                                </li>
                                {/* <li style={{ marginBottom: "5px" }}>
                                    <strong>Green Zone:</strong> Indicates that the BOP Run Rate is less than or equal to the Daily Run Rate, meaning you're on track to meet the target (less than or equal to 100%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Red Zone:</strong> Indicates that the BOP Run Rate is greater than the Daily Run Rate, meaning you're not on track to meet the target (greater than 100%).
                                </li> */}
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Color Coding</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "green" }}>Green:</strong> Indicates you're comfortably on track to exceed your target, keep up the great work! (less than 90%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "orange" }}>Amber:</strong> Indicates you're on track to meet the target, maintain your current pace to avoid falling behind (between 90% and 100%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "red" }}>Red:</strong> Indicates you're not on track to meet the target, work harder to catch up (greater than 100%).
                                </li>
                            </ul>
                        </div>
                    </OverlayPanel>
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}

                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-3">
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor2(actualVsTargetChange) }}>BOP is {actualVsTargetChange}% of Daily Run Rate</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Daily Run Rate: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.target)}
                        </span>
                    </div>
                )}
                {actualVsSPLYChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor2(actualVsSPLYChange) }}>BOP is {actualVsSPLYChange}% of Daily Run Rate SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Daily Run Rate SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.sply)}
                        </span>
                    </div>
                )}
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const SpotLandingCard = ({ title, unit, categories, tooltip, actual, target, chartData, color, measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);
    const op = useRef(null);
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    //pasted
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [
        //     { id: 1, label: "Pending", value: "PENDING" },
        //     { id: 2, label: "Closed", value: "CLOSED" },
        // ],
        orderBy: { id: 2, label: "Descending", value: "desc" },
        dataLimit: { id: 3, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SpotLandingQuery = useQuery({
        disable: false,
        queryKey: ["sell-in-spot-landing", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinSpotLanding({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SpotLandingQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SpotLandingQuery?.isError:", SpotLandingQuery?.error);
            SpotLandingQuery?.error?.response?.data?.message ? toast.error(SpotLandingQuery?.error?.response?.data?.message) : !SpotLandingQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SpotLandingQuery?.isError]);

    if (SpotLandingQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (SpotLandingQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo SpotLandingQuery?.data?.data is:", SpotLandingQuery?.data?.data);

    // console.log("zinasd", sampleData, SpotLandingQuery?.data?.data);

    const data = SpotLandingQuery?.data?.data?.data;

    const actualVsTargetChange = calculatePercentageChange(data.actual, data.target);
    const actualVsSPLYChange = calculatePercentageChange(data.actual, data.sply);

    // Define the series options based on chartData
    const options = {
        chart: {
            type: "column", // Using column chart for SPLY vs Actual
            height: "190",
            // backgroundColor: "#f4f4f4",
        },
        title: {
            text: data.title,
        },
        xAxis: {
            categories: data.categories,
        },
        yAxis: {
            // min: 0,
            title: {
                text: data.unit,
            },
        },
        series: [
            {
                name: "Spot Landing",
                // colorByPoint: true,
                data: data.chartData,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "11px", // Set the font size of the data labels
                    },
                },
            },
        ],

        tooltip: {
            formatter: function () {
                return `<b>${this.x}</b><br/>${measure === "quantity" ? "" : "UGX"} ${this.y.toLocaleString()}`;
            },
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                },
                enableMouseTracking: true,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0" style={{ height: "300px" }}>
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{data.title}</span>
                    <Tooltip title={data.tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            Spot Landing: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center"
                    // style={{
                    //     width: "2.5rem",
                    //     height: "2.5rem",
                    // }}
                    // onClick={() => setVisible(true)}
                >
                    {/* <div style={{ position: "relative", top: "30px", marginTop: "-30px", zIndex: "20", width: "fit-content" }}> */}
                    <Tooltip title="How to interpret this chart">
                        <IconButton onClick={(e) => op.current.toggle(e)}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    {/* </div> */}
                    <OverlayPanel ref={op} style={{ maxWidth: "80vw", padding: "20px" }}>
                        <div>
                            <h4>Interpreting Spot Landing vs Target</h4>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Overview</h5>
                            <p>The spot landing vs target metric assesses performance against the set target, providing a quick and intuitive way to gauge progress.</p>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Key Components</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Spot Landing:</strong> Calculates the projected revenue if the current trend continues for the entire period.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Target:</strong> The set revenue goal for the period.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Percentage Variance:</strong> Compares the spot landing to the target and SPLY revenue.
                                </li>
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Calculating Spot Landing and Percentage Variance</h5>
                            <p>
                                <strong>Spot Landing:</strong> (Revenue / Period Gone) * Total Period
                                <br />
                                <br />
                                <strong>Percentage Variance:</strong>
                                <ul>
                                    <li>(Spot Landing / Target) * 100</li>
                                    <li>(Spot Landing / Revenue SPLY) * 100</li>
                                </ul>
                            </p>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Color Coding for Percentages</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "red" }}>Red:</strong> Indicates less than 90% of the target or SPLY.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "orange" }}>Amber:</strong> Indicates between 90% and 100% of the target or SPLY.
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "green" }}>Green:</strong> Indicates more than 100% of the target or SPLY.
                                </li>
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Interpretation</h5>
                            <p>
                                Use the percentage variance to evaluate performance against the target and SPLY. Adjust strategies to meet or exceed the target based on the color coding:
                                <ul>
                                    <li style={{ color: "red" }}>Red: Below target or SPLY, adjust strategies to improve performance.</li>
                                    <li style={{ color: "orange" }}>Amber: Close to target or SPLY, maintain or slightly adjust strategies.</li>
                                    <li style={{ color: "green" }}>Green: Exceeding target or SPLY, continue successful strategies.</li>
                                </ul>
                            </p>
                        </div>
                    </OverlayPanel>
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-3">
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>Spot Landing is {actualVsTargetChange}% of the Target</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.target)}
                        </span>
                    </div>
                )}
                {actualVsSPLYChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSPLYChange) }}>Spot Landing is {actualVsSPLYChange}% of SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.sply)}
                        </span>
                    </div>
                )}
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

const RevenueSalesCardCharts = ({ measure = "sales_value" }) => {
    const data = {
        revenue: {
            title: "Revenue Today",
            categories: ["21st", "22nd", "23rd"],
            chartData: [
                {
                    name: "SPLY",
                    data: [91300, 92300, 93300],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [92100, 93100, 94100],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [88500, 89500, 95000],
                    color: "#1e90ff",
                },
            ],
            tooltip: {
                definition: "Total income generated from sales.",
                formula: "Revenue = Actual Sales Amount",
            },
        },

        revenueWTD: {
            title: "Revenue WTD",
            categories: ["Week 28", "Week 29", "Week 30"],
            actual: 4640,
            sply: 4500,
            unit: "K/day",
            chartData: [
                {
                    name: "SPLY",
                    data: [88000, 89000, 90000],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [91000, 92000, 93000],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [92000, 93000, 94000],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
        revenueMTD: {
            title: "Revenue MTD",
            categories: ["May", "Jun", "Jul"],
            actual: 1690000,
            sply: 1600000,
            unit: "K",
            chartData: [
                {
                    name: "SPLY",
                    data: [970000, 950000, 940000],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [920000, 930000, 940000],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [910000, 920000, 930000],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The total revenue projected based on current run rate.",
            },
        },
        revenueYTD: {
            title: "Revenue YTD",
            categories: ["2022", "2023", "2024"],
            actual: 1690000,
            sply: 1600000,
            unit: "K",
            chartData: [
                {
                    name: "SPLY",
                    data: [880000, 890000, 900000],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [920000, 930000, 940000],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [910000, 920000, 930000],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The total revenue projected based on current run rate.",
            },
        },
        dailyRunRate: {
            title: "BOP Run Rate Vs Daily Run Rate",
            categories: ["BOP Run Rate", "Daily Run Rate"],
            actual: 4440,
            target: 4500,
            unit: "Revenue",
            chartData: [
                // Daily Run Rate
                { y: 4440, color: 4440 > 4500 ? "#FF7F7F" : "#90EE90" }, // BOP Run Rate
                { y: 4500 },
            ], // Updated to an array of numbers
            color: "blue",
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
        spotLanding: {
            title: "Spot Landing Vs Target",
            categories: ["Spot Landing", "Target"],
            actual: 4440,
            target: 4500,
            unit: "Revenue",
            chartData: [
                // Daily Run Rate
                { y: 4440, color: 4440 > 4500 ? "#FF7F7F" : "#90EE90" }, // BOP Run Rate
                { y: 4500 },
            ], // Updated to an array of numbers
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
    };

    return (
        // <div style={{ padding: "10px" }}>
        <Grid style={{ padding: "10px" }} container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                    {/* <SalesCard {...revenueTD[0]} measure={measure} /> */}
                    <RevenueTodayCard measure={measure} />
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                {/* <SalesCard {...revenueTD[1]} measure={measure} /> */}
                <RevenueWeekToDate measure={measure} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                {/* <SalesCard {...revenueTD[2]} measure={measure} /> */}
                <RevenueMonthToDate measure={measure} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                {/* <SalesCard {...revenueTD[3]} measure={measure} /> */}
                <RevenueYearToDate measure={measure} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <SellinStatsGaugeTarget
                    measure={measure}
                    // revenueData={{
                    //     actual: 290000000000, // in millions
                    //     target: 240000000000, // percentage of annual target
                    //     title: "Revenue from 1st Jan 2024 to 23rd May 2024", // percentage of annual target
                    // }}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <SpotLandingCard {...data.spotLanding} measure={measure} />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <RunRateCard {...data.dailyRunRate} measure={measure} />
            </Grid>
        </Grid>
        // </div>
    );
};

export default RevenueSalesCardCharts;
