import React, { useState, useContext, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { getSelloutRoutesPerfomanceTable } from "../../services/tarbular-perfomance/sell-out-tarbular-perfomance";

import MuiTable from "../../components/general_components/MuiTable";

import { Panel } from "primereact/panel";
import moment from "moment";

//
import FiltersFormDialog from "./widgets/FiltersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";
import { NiceTwoDBFilterContext } from "../../context/NiceTwoDBFilterContext";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useAuthContext from "../../context/AuthContext";

//
import Lottie from "lottie-react";
import BrownFilesProcessingLottie from "../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
import MaterialUiLoaderLottie from "../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import SnailErrorLottie from "../../assets/lotties/nice-hop/snail-error-lottie.json";

import numeral from "numeral";

function RoutesTabularPerformanceTable({ loggedInUserData, ...props }) {
    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    // Helper function to format numbers using numeral
    const formatNumber = (value) => {
        return numeral(value).format("0.0a").toUpperCase(); // Formats numbers to 'K', 'M', 'B'
    };

    // Function to format percentages
    const formatPercentage = (value) => {
        const numericValue = parseFloat(value); // Convert to a number first
        return `${Math.round(numericValue)}%`; // Round the numeric value and append '%'
    };

    // Helper function to get color for percentage columns
    const getPercentageColor = (value) => {
        const numericValue = parseFloat(value);

        if (numericValue < 90) {
            return "red"; // Less than 90%
        } else if (numericValue >= 90 && numericValue <= 99) {
            return "orange"; // Between 90% and 98%
        } else if (numericValue >= 100) {
            return "green"; // 100% or more
        } else {
            return "black"; // Default color for other values
        }
    };

    const columns = [
        { title: "Routes", field: "route" },
        {
            title: "Total Targets",
            field: "total_targets",
            render: (rowData) => formatNumber(rowData.total_targets),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Actuals (Current Month)",
            field: "actuals_current_month",
            render: (rowData) => formatNumber(rowData.actuals_current_month),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Variance",
            field: "variance",
            render: (rowData) => formatNumber(rowData.variance),
            cellStyle: (cellData) => ({
                textAlign: "right",
            }),
        },
        {
            title: "Actuals (Full Month Last Year)",
            field: "actuals_full_month_last_year",
            render: (rowData) => formatNumber(rowData.actuals_full_month_last_year),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Daily Run Rate (DRR)",
            field: "drr",
            render: (rowData) => formatNumber(rowData.drr),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Balance of Period Run Rate (BOP RR)",
            field: "bop",
            render: (rowData) => formatNumber(rowData.bop),
            cellStyle: (cellData) => ({
                textAlign: "right",
            }),
        },
        {
            title: "Spot Landing",
            field: "spot_landing",
            render: (rowData) => formatNumber(rowData.spot_landing),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Previous Day Actual",
            field: "previous_day_actual",
            render: (rowData) => formatNumber(rowData.previous_day_actual),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Today Actual",
            field: "today_actual",
            render: (rowData) => formatNumber(rowData.today_actual),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Week to Date Target (WTDT)",
            field: "wtd_tgt",
            render: (rowData) => formatNumber(rowData.wtd_tgt),
            cellStyle: { textAlign: "right" },
        },
        {
            title: "Week to Date Actual (WTD)",
            field: "wtd_actual",
            render: (rowData) => formatNumber(rowData.wtd_actual),
            cellStyle: { textAlign: "right" },
        },
        // Percentage columns (formatted with % and without numeral)
        {
            title: "% MTD ACT VS MTD Target",
            field: "percentage_mtd_vs_mtd_target",
            render: (rowData) => (
                <div
                    style={{
                        color: getPercentageColor(rowData?.percentage_mtd_vs_mtd_target),
                        fontWeight: "bold",
                        textAlign: "right",
                    }}
                >
                    {formatPercentage(rowData?.percentage_mtd_vs_mtd_target)}
                </div>
            ),
        },
        {
            title: "% MTD ACT VS LY MTD",
            field: "percentage_mtd_vs_ly_mtd",
            render: (rowData) => (
                <div
                    style={{
                        color: getPercentageColor(rowData?.percentage_mtd_vs_ly_mtd),
                        fontWeight: "bold",
                        textAlign: "right",
                    }}
                >
                    {formatPercentage(rowData?.percentage_mtd_vs_ly_mtd)}
                </div>
            ),
        },
        {
            title: "% WTD ACT VS WTD Target",
            field: "percentage_wtd_vs_wtd_target",
            render: (rowData) => (
                <div
                    style={{
                        color: getPercentageColor(rowData?.percentage_wtd_vs_wtd_target),
                        fontWeight: "bold",
                        textAlign: "right",
                    }}
                >
                    {formatPercentage(rowData?.percentage_wtd_vs_wtd_target)}
                </div>
            ),
        },
        {
            title: "% Spot Landing Vs Full Month Target",
            field: "percentage_spot_vs_full_month_target",
            render: (rowData) => (
                <div
                    style={{
                        color: getPercentageColor(rowData?.percentage_spot_vs_full_month_target),
                        fontWeight: "bold",
                        textAlign: "right",
                    }}
                >
                    {formatPercentage(rowData?.percentage_spot_vs_full_month_target)}
                </div>
            ),
        },
        {
            title: "% Spot Landing Vs Full Month LY",
            field: "percentage_spot_vs_full_month_ly",
            render: (rowData) => (
                <div
                    style={{
                        color: getPercentageColor(rowData?.percentage_spot_vs_full_month_ly),
                        fontWeight: "bold",
                        textAlign: "right",
                    }}
                >
                    {formatPercentage(rowData?.percentage_spot_vs_full_month_ly)}
                </div>
            ),
        },
    ];

    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        date: moment().format("YYYY-MM-DD"),
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            date: moment().format("YYYY-MM-DD"),
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["sellout-routes-tarbular-performance-table", ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSelloutRoutesPerfomanceTable({ ...filtersFormInitialDataValues }),
    });
    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    //
    const totals = data?.data?.totals;
    console.log("🚀 ~ RoutesTabularPerformanceTable ~ totals:", totals);

    //================================= get title =======================

    const dataFilters = data?.data?.requestParams;

    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        if (!dataFilters) {
            return null;
        }
        // Format the dates
        // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        const date = dataFilters?.date ? moment(dataFilters.date).format("MMMM Do, YYYY") : null;

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        // const orderBy = dataFilters?.orderBy?.label;
        // const dataLimit = dataFilters?.dataLimit?.label;
        // const dataLimitNumber = dataFilters?.dataLimitNumber;
        // const viewInterval = dataFilters?.viewInterval?.label;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "";
        if (date) sentence += `Date: ${date}. `;
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        // if (orderBy) sentence += `Order By: ${orderBy}. `;
        // if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        // if (dataLimitNumber) sentence += `Data Limit No: ${dataLimitNumber}. `;
        // if (viewInterval) sentence += `View Interval: ${viewInterval}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        // if (startDate) sentence += `Start Date: ${startDate}. `;
        // if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    const filtersTitle = formatFilters(dataFilters);

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Routes Tabular Perfomance" style={{ marginBottom: "20px" }} toggleable>
                {isLoading ? (
                    <>
                        <div className="col-12">
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ maxWidth: "400px" }}>
                                    <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                                </div>
                            </div>
                            {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                        </div>
                    </>
                ) : isError ? (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="grid p-3">
                            <div className="col-10 md:col-10 lg:col-10">
                                {filtersTitle && (
                                    <>
                                        <p>
                                            <strong>Filters: </strong>
                                            {filtersTitle}
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className="col-2 md:col-2 lg:col-2 flex justify-content-end">
                                <Tooltip title="Filter">
                                    <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                                </Tooltip>

                                <FiltersFormDialog
                                    onSubmit={handleSubmitForFilters}
                                    filtersFormInitialDataValues={filtersFormInitialDataValues}
                                    setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                                    showFiltersFormDialog={showFiltersFormDialog}
                                    setShowFiltersFormDialog={setShowFiltersFormDialog}
                                />
                            </div>
                        </div>
                        {totals && (
                            <div className="flex align-items-center justify-content-center flex-column" style={{ padding: "1rem" }}>
                                <div className="flex align-self-start">
                                    <h3>Totals</h3>
                                </div>
                                <div className="grid">
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Total Targets: The target revenue set for the current month across all regions.">
                                            <strong>Total Targets: </strong>
                                        </Tooltip>
                                        {formatNumber(totals.total_targets)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Actuals (Current Month): The sum of revenue from orders made so far this month.">
                                            <strong>Actuals (Current Month): </strong>
                                        </Tooltip>
                                        {formatNumber(totals.actuals_current_month)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Variance: The difference between Actuals (Current Month) and Total Targets.">
                                            <strong>Variance: </strong>
                                        </Tooltip>
                                        <span>{formatNumber(totals.variance)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Actuals (Full Month Last Year): The sum of the revenue from last year's orders for the entire month.">
                                            <strong>Actuals (Full Month Last Year): </strong>
                                        </Tooltip>
                                        {formatNumber(totals.actuals_full_month_last_year)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Daily Run Rate (DRR): Total Targets divided by the number of days in the month.">
                                            <strong>Daily Run Rate (DRR): </strong>
                                        </Tooltip>
                                        {formatNumber(totals.drr)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Balance of Period Run Rate (BOP RR): (Total Targets - Actuals for Current Month) divided by the number of days remaining in the month.">
                                            <strong>Balance of Period Run Rate (BOP RR): </strong>
                                        </Tooltip>
                                        <span>{formatNumber(totals.bop)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Spot Landing: The projected revenue for the full month based on current period revenue and time elapsed. Calculated as (Actuals / Period Gone) * Total Period.">
                                            <strong>Spot Landing: </strong>
                                        </Tooltip>
                                        {formatNumber(totals.spot_landing)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Today Actual: The revenue generated from sales made today.">
                                            <strong>Today Actual: </strong>
                                        </Tooltip>
                                        {formatNumber(totals.today_actual)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Previous Day Actual: The total revenue generated from sales on the previous day.">
                                            <strong>Previous Day Actual: </strong>
                                        </Tooltip>
                                        {formatNumber(totals.previous_day_actual)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Week to Date Target (WTDT): Daily Run Rate multiplied by number of day gone in current weeek to represent a weekly target.">
                                            <strong>Week to Date Target (WTDT): </strong>
                                        </Tooltip>
                                        {formatNumber(totals.wtd_tgt)}
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Week to Date Actual (WTD): The total revenue generated from sales from the start of the week to the current day.">
                                            <strong>Week to Date Actual (WTD): </strong>
                                        </Tooltip>
                                        {formatNumber(totals.wtd_actual)}
                                    </div>

                                    {/* Percentage Fields */}
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Percentage MTD vs MTD Target: The percentage of actuals compared to the monthly target.">
                                            <strong>% MTD vs MTD Target: </strong>
                                        </Tooltip>
                                        <span style={{ color: getPercentageColor(totals?.percentage_mtd_vs_mtd_target) }}>{formatPercentage(totals?.percentage_mtd_vs_mtd_target)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Percentage MTD vs Last Year MTD: The percentage comparison of current month actuals to last year’s same month.">
                                            <strong>% MTD vs LY MTD: </strong>
                                        </Tooltip>
                                        <span style={{ color: getPercentageColor(totals?.percentage_mtd_vs_mtd_target) }}>{formatPercentage(totals?.percentage_mtd_vs_ly_mtd)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Percentage WTD vs WTD Target: The percentage of actuals compared to the week-to-date target.">
                                            <strong>% WTD vs WTD Target: </strong>
                                        </Tooltip>
                                        <span style={{ color: getPercentageColor(totals?.percentage_wtd_vs_wtd_target) }}>{formatPercentage(totals?.percentage_wtd_vs_wtd_target)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Percentage Spot vs Full Month Target: The percentage of spot landing compared to the full month target.">
                                            <strong>% Spot Landing vs Full Month Target: </strong>
                                        </Tooltip>
                                        <span style={{ color: getPercentageColor(totals?.percentage_spot_vs_full_month_target) }}>{formatPercentage(totals?.percentage_spot_vs_full_month_target)}</span>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Tooltip title="Percentage Spot vs Full Month LY: The percentage of spot landing compared to last year’s full month.">
                                            <strong>% Spot Landing vs Full Month LY: </strong>
                                        </Tooltip>
                                        <span style={{ color: getPercentageColor(totals?.percentage_spot_vs_full_month_ly) }}>{formatPercentage(totals?.percentage_spot_vs_full_month_ly)}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        <MuiTable
                            tableTitle="Routes Tabular Perfomance"
                            tableData={data?.data?.data ?? []}
                            tableColumns={columns}
                            loading={isLoading}
                            //
                            showSummaryRow={true}
                            summaryValues={totals}
                            //
                            exportButton={true}
                            pdfExportTitle="Routes Perfomance"
                            csvExportTitle="Routes Perfomance"
                        />
                    </>
                )}
            </Panel>
        </div>
    );
}

export default RoutesTabularPerformanceTable;
