import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import numeral from "numeral";

//
import { IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";

import { getRunRate, getSpotlandingData, getRevenueToDate } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import RobotProcessorLottie from "../../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

//
import CardsFiltersFormDialog from "./widgets/CardsFiltersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

//
const formatNumber = (number) => {
    return numeral(number).format("0.[00]a").toUpperCase(); // e.g., 3.5k, 3.45m, 3.4b
};

const getChangeColor = (change) => {
    if (change > 100) {
        return "green";
    } else if (change < 90) {
        return "red";
    } else {
        return "orange";
    }
};

const getChangeColor2 = (change) => {
    if (change > 100) {
        return "red";
    } else if (change < 90) {
        return "green";
    } else {
        return "orange";
    }
};

const RunRateCard = ({ title, unit, categories, tooltip, actual, target, chartData, color, measure = "sales_value" }) => {
    const [visible, setVisible] = useState(false);

    const op = useRef(null);

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const RunRateQuery = useQuery({
        disable: false,
        queryKey: ["run-rate", measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRunRate({ measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RunRateQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RunRateQuery?.isError:", RunRateQuery?.error);
            RunRateQuery?.error?.response?.data?.message ? toast.error(RunRateQuery?.error?.response?.data?.message) : !RunRateQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RunRateQuery?.isError]);

    if (RunRateQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (RunRateQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo RunRateQuery?.data?.data is:", RunRateQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, RunRateQuery?.data?.data);

    const data = RunRateQuery?.data?.data?.data;

    const actualVsTargetChange = calculatePercentageChange(data.actual, data.target);
    const actualVsSPLYChange = calculatePercentageChange(data.actual, data.sply);

    // Define the series options based on chartData
    // Define the series options based on chartData
    const options = {
        chart: {
            type: "column", // Using column chart for SPLY vs Actual
            height: "190",
            // backgroundColor: "#f4f4f4",
        },
        title: {
            text: data.title,
        },
        xAxis: {
            categories: data.categories,
        },
        yAxis: {
            // min: 0,
            title: {
                text: data.unit,
            },
        },
        series: [
            {
                name: "Run Rate",
                // colorByPoint: true,
                data: data.chartData,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "11px", // Set the font size of the data labels
                    },
                    format: `${measure === "quantity" ? "" : "UGX"} {point.y:,.0f}`,
                    // formatter: function () {
                    //     return `<b>${this.x}</b><br/>UGX ${this.y.toLocaleString()}`;
                    // },
                },
            },
        ],

        tooltip: {
            formatter: function () {
                return `<b>${this.x}</b><br/>${measure === "quantity" ? "" : "UGX"} ${this.y.toLocaleString()}`;
            },
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                },
                enableMouseTracking: true,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div className="card mb-0" style={{ height: "300px" }}>
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{data.title}</span>
                    <Tooltip title={data.tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>
                            BOP Run Rate: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.actual)}
                        </div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center"

                    // onClick={() => setVisible(true)}
                >
                    {/* <div style={{ position: "relative", top: "30px", marginTop: "-30px", zIndex: "20", width: "fit-content" }}> */}
                    <Tooltip title="How to interpret this chart">
                        <IconButton onClick={(e) => op.current.toggle(e)}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    {/* </div> */}
                    <OverlayPanel ref={op} style={{ maxWidth: "80vw", padding: "20px" }}>
                        <div>
                            <h4>Run Rate Card Guide</h4>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Overview</h5>
                            <p>The Run Rate Card provides a snapshot of progress towards a target, helping you gauge performance and stay on track.</p>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Key Components</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Daily Run Rate:</strong> Calculates the required daily rate to meet the target based on the total days in the period.
                                    <br />
                                    <strong>Formula:</strong> Daily Run Rate = Target / Total Number of Days in the period
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Balance of Period Run Rate (BOP Run Rate):</strong> Calculates the required rate to meet the target based on the remaining days in the period.
                                    <br />
                                    <strong>Formula:</strong> BOP Run Rate = Balance of Target / Number of Days Left to the end of the period
                                </li>
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Interpretation</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Percentage of Daily Run Rate</strong>
                                    <br />
                                    <strong>Formula:</strong> (BOP Run Rate / Daily Run Rate) * 100
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Percentage of Daily Run Rate SPLY</strong>
                                    <br />
                                    <strong>Formula:</strong> (BOP Run Rate / Daily Run Rate SPLY) * 100
                                </li>
                                {/* <li style={{ marginBottom: "5px" }}>
                                    <strong>Green Zone:</strong> Indicates that the BOP Run Rate is less than or equal to the Daily Run Rate, meaning you're on track to meet the target (less than or equal to 100%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong>Red Zone:</strong> Indicates that the BOP Run Rate is greater than the Daily Run Rate, meaning you're not on track to meet the target (greater than 100%).
                                </li> */}
                            </ul>
                            <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Color Coding</h5>
                            <ul style={{ paddingLeft: "20px" }}>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "green" }}>Green:</strong> Indicates you're comfortably on track to exceed your target, keep up the great work! (less than 90%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "orange" }}>Amber:</strong> Indicates you're on track to meet the target, maintain your current pace to avoid falling behind (between 90% and 100%).
                                </li>
                                <li style={{ marginBottom: "5px" }}>
                                    <strong style={{ color: "red" }}>Red:</strong> Indicates you're not on track to meet the target, work harder to catch up (greater than 100%).
                                </li>
                            </ul>
                        </div>
                    </OverlayPanel>
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}

                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-3">
                {actualVsTargetChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor2(actualVsTargetChange) }}>BOP is {actualVsTargetChange}% of Daily Run Rate</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Daily Run Rate: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.target)}
                        </span>
                    </div>
                )}
                {actualVsSPLYChange && (
                    <div className={`font-medium flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor2(actualVsSPLYChange) }}>BOP is {actualVsSPLYChange}% of Daily Run Rate SPLY</span>{" "}
                        <span style={{ fontSize: "10px" }}>
                            Daily Run Rate SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(data.sply)}
                        </span>
                    </div>
                )}
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};
export default RunRateCard;
