import axiosAPI from "../axiosApi";

//=================== perfomance ======================

export async function getSellinRegionsPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSellinRegionsPerfomanceTable", { params: params });
    return response;
}

export async function getSellinChannelsPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSellinChannelsPerfomanceTable", { params: params });
    return response;
}

export async function getSellinAgentsPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSellinAgentsPerfomanceTable", { params: params });
    return response;
}

export async function getSellinSubcategoriesPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSellinSubcategoriesPerfomanceTable", { params: params });
    return response;
}

export async function getSellinCategoriesPerfomanceTable(params = {}) {
    const response = await axiosAPI.get("getSellinCategoriesPerfomanceTable", { params: params });
    return response;
}
